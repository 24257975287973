<script setup lang="ts">
import navigationItems from '@/navigation'
import { breakpointsVuetify, useWindowSize, type MaybeRef } from '@vueuse/core'
import { computed, unref, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useCookies } from '@vueuse/integrations/useCookies'

// ✨ controll the appearance of app bar nav icon
const { width: windowWidth } = useWindowSize()
const isLessThanOverlayNavBreakpoint = computed(() => {
  return (windowWidth: MaybeRef<number>) => unref(windowWidth) < breakpointsVuetify.md
})

// ✨ get user data
const { user, setUser } = useUserStore()

// ✨ logout
const { remove } = useCookies(['token'])
const router = useRouter()
const logout = () => {
  remove('token')
  setUser(null)
  router.push('/login')
}

// ✨ handle open and close navigation drawer
const navController = ref(true)
function controllNavToggle() {
  navController.value = !navController.value
}
const baseUrl = import.meta.env.VITE_SERVER_API_URL
function resetPassword() {
  window.open(baseUrl + '/my-account/edit-account/?login=true&type=forgot-password')
}
function editProfile() {
  window.open(baseUrl + '/my-account/edit-account/?login=true')
}
</script>

<template>
  <v-layout class="rounded rounded-md">
    <v-navigation-drawer v-model="navController" style="position: fixed" width="300">
      <v-list>
        <v-list-item :title="user?.displayName" :subtitle="user?.email" style="text-align: center">
          <template #prepend>
            <v-row>
              <v-col>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <span
                      class="mdi mdi-dots-vertical"
                      style="font-size: x-large"
                      v-bind="props"
                    ></span>
                  </template>
                  <v-list>
                    <v-list-item
                      title="تعديل الحساب"
                      prepend-icon="mdi-store-edit"
                      active-class="active-nav"
                      @click="editProfile"
                    ></v-list-item>
                    <v-list-item
                      title="تعديل كلمه السر"
                      prepend-icon="mdi mdi-lock-reset"
                      active-class="active-nav"
                      @click="resetPassword"
                    ></v-list-item>
                    <v-list-item
                      title=" تسجيل خروج "
                      prepend-icon="mdi-logout-variant"
                      active-class="active-nav"
                      @click.prevent="logout"
                    ></v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </template>
          <template #append>
            <v-btn
              icon="mdi-logout-variant"
              variant="plain"
              @click.prevent="logout"
              data-cy="logout-button"
            />
          </template>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list class="mt-1">
        <div
          v-for="navigationItem in navigationItems"
          :key="navigationItem.id"
          :data-cy="`navbar-${navigationItem.key}`"
        >
          <v-list-item
            :title="navigationItem.title"
            :to="navigationItem.to"
            :prepend-icon="navigationItem.icon.icon"
            active-class="active-nav"
          ></v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-locale-provider locale="en">
      <v-app-bar style="position: fixed; display: flex; justify-content: space-between">
        <div
          style="
            width: 100%;
            height: 100%;
            padding: 0;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="bg-black h-100 flex pa-2">
            <img src="@/assets/images/login-background.png" class="bg-black" style="width: 120px" />
          </div>
          <v-app-bar-nav-icon
            v-if="isLessThanOverlayNavBreakpoint(windowWidth)"
            @click="controllNavToggle"
          >
          </v-app-bar-nav-icon>
        </div>
      </v-app-bar>
    </v-locale-provider>

    <v-main style="min-height: 100vh" class="pb-12">
      <div class="pa-4">
        <slot />
      </div>
    </v-main>
  </v-layout>
</template>
<style scoped>
.flex {
  display: flex;
  align-items: center;
}
</style>
