// Vuetify
import '@/styles/style.scss'
import { createVuetify } from 'vuetify'

import '@mdi/font/css/materialdesignicons.css'
import theme from './theme'

import { ar } from 'vuetify/locale'

const vuetify = createVuetify({
  components: {},
  theme,
  locale: {
    locale: 'ar',
    fallback: 'ar',
    messages: { ar },
    rtl: { ar: true }
  }
})

export default vuetify
