import cacheApi from '@/core/cacheApi'
import { toast } from 'vue3-toastify'

import { formatCategoryName } from '@/helpers/utils'

import type { ApiErrorResponse, ApiResponse } from '@/types/apiResponse'
import type { Category } from '@/types/category'
import type { QueryParameters } from '@/types'
import { useLanguage } from '@/composables/language'
const { mainLang } = useLanguage()
export async function getCategories({
  page,
  itemsPerPage,
  parent,
  search
}: QueryParameters): Promise<ApiResponse> {
  try {
    const { data, headers } = await cacheApi.get(`/wp-json/wc/v3/products/categories`, {
      params: {
        page: page || 1,
        per_page: itemsPerPage || 10,
        parent: parent || null,
        search: search || null,
        lang: mainLang
      }
    })

    const categories: Category[] = data.map((category: Category) => ({
      id: category.id,
      name: formatCategoryName(category.name),
      slug: category.slug,
      parent: category.parent,
      description: category.description,
      display: category.display,
      image: category.image,
      menu_order: category.menu_order,
      count: category.count
    }))

    return {
      success: true,
      data: {
        categories,
        total_items: parseInt(headers['x-wp-total'])
      }
    }
  } catch (error) {
    toast.error('حدث خطأ في العملية')
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
