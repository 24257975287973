import { formatCategoryName, formatDescription, mapVisibility } from './utils'
import type { Product } from '@/types/product'
import type { Variation } from '@/types/product'
import type { Image } from '@/types/image'
import type { Attribute } from '@/types/attribute'
import type { Category } from '@/types/category'
import type { Option } from '@/types/option'

export function formatProduct(product: any) {
  const newProduct: Product = {
    id: product.id as number,
    name: product.name,
    regular_price: product.regular_price,
    sale_price: product.sale_price,
    description: formatDescription(product.description),
    base_price: product.price,
    images: [],
    meta_data: product.meta_data,
    attributes: [],
    variations: [],
    categories: [],
    slug: product.slug,
    type: product.type,
    price: product.price,
    default_attributes: product.default_attributes,
    visible: true,
    total_sales: product.total_sales,
    manage_stock: product.manage_stock,
    stock_quantity: product.manage_stock ? product.stock_quantity : null,
    menu_order: product.menu_order,
    translations: product.translations,
    is_original_product: product.is_original_product,
    lang: product.lang,
    translations_strings: product.translations_strings
  }

  newProduct.visible = mapVisibility(product.catalog_visibility) as boolean

  for (const image of product.images) {
    const newImage: Image = { id: image.id, src: image.src }
    newProduct.images.push(newImage)
  }

  for (const category of product.categories) {
    const newCategory: Category = {
      id: category.id as number,
      name: formatCategoryName(category.name),
      translation_strings: category.translation_strings
    }
    newProduct.categories.push(newCategory)
  }
  for (const attribute of product.new_attributes) {
    const newAttribute: Attribute = {
      id: attribute.id as number,
      name: attribute.name.replace('pa_', ''),
      options: [],
      slug: '',
      type: '',

      menu_order: 0
    }
    if (attribute.options) {
      for (const option of attribute.options) {
        const newOption: Option = {
          id: option.id as string,
          name: option.name,
          type: option.type,
          sw_image: option.sw_image,
          sw_color: option.sw_color,
          slug: slugify(option.name),
          menu_order: option.menu_order || 0,
          translations: option.translations,
          lang: product.lang,
          translations_names: option.translations_names,
          translation_strings: option.translation_strings
        }
        newAttribute.options?.push(newOption)
        newAttribute.options?.sort((a, b) => a.menu_order - b.menu_order)
      }
    }
    newProduct.attributes.push(newAttribute)
  }

  for (const variation of product.new_variations) {
    const newVariation: Variation = {
      id: variation.variation_id as number,
      image: variation.image_url,
      image_id: variation.image_id,
      price: parseInt(variation.price),
      sku: variation.sku,
      sale_price: parseInt(variation.sale_price),
      stock_quantity: variation.stock_quantity,
      manage_stock: variation.manage_stock,
      attributes: []
    }

    for (const attribute of variation.attributes) {
      newVariation.attributes.push({
        attribute_id: attribute.attribute_id as string,
        attribute_name: attribute.attribute_name.replace('pa_', ''),
        option_id: attribute.attribute_option_id as string,
        option_name: attribute.attribute_option_name
      })
    }

    newProduct.variations.push(newVariation)
  }

  return newProduct
}

function slugify(text: string): string {
  return text.trim().replace(/\W+/g, '-').toLowerCase()
}

// Function to generate all variations
function generateVariations(attributesData: any) {
  const variations: any = []
  // Recursive function to generate variations
  function generate(currentVariation: any, index: any) {
    if (index === attributesData.length) {
      // If all attributes are processed, add the current variation to the result
      variations.push(currentVariation)
      return
    }

    const attribute = attributesData[index]

    for (const option of attribute.options) {
      // Clone the current variation to avoid modifying it for other iterations
      const newVariation = [...currentVariation]

      // Add the current attribute and option to the new variation
      newVariation.push({
        attribute_id: attribute.id,
        attribute_name: attribute.name,
        option_id: option.id,
        option_name: option.name
      })

      // Recursively generate variations for the next attribute
      generate(newVariation, index + 1)
    }
  }
  // Start the variation generation process
  generate([], 0)
  return variations
}
function replaceVariations(existingVariations: any[], updatedVariations: any[]): any[] {
  const updatedVariationsMap = new Map()

  // Create a map for quick lookup of updated variations
  const result: any = []
  updatedVariations.forEach((updated: any) => {
    const sortedAttributes = updated.attributes
      .slice() // create a copy to avoid modifying the original array
      .sort((a: any, b: any) => a.attribute_id - b.attribute_id)

    const key = sortedAttributes
      .map((attr: any) => `${attr.attribute_id}-${attr.option_id}`)
      .join(',')

    if (updatedVariationsMap.has(key)) {
      updated['duplicated'] = true
      result.push(updated)
    } else {
      if (updated.attributes?.length != existingVariations[0].attributes?.length)
        updated['duplicated'] = true
      updatedVariationsMap.set(key, updated)
      result.push(updated)
    }
  })

  // Replace existing variations with the updated ones

  existingVariations.map((existing: any) => {
    const sortedAttributes = existing.attributes
      .slice() // create a copy to avoid modifying the original array
      .sort((a: any, b: any) => a.attribute_id - b.attribute_id)

    const key = sortedAttributes
      .map((attr: any) => `${attr.attribute_id}-${attr.option_id}`)
      .join(',')
    if (!updatedVariationsMap.has(key)) result.push(existing)
    if (updatedVariationsMap.has(key) && existing.id > -1) {
      existing['duplicated'] = true
      result.push(existing)
    }
  })

  return result
}

export function getVariations(attributesData: any, productVariations: any) {
  if (!attributesData[0]?.options?.length) return []
  const allVariations = generateVariations(attributesData)

  // Create the desired array structure
  const resultArray = allVariations.map((variation: any) => ({
    id: '-1',
    image: false,
    image_id: null,
    price: 0,
    sku: '',
    sale_price: null,
    stock_quantity: '',
    attributes: variation
  }))
  const finalResult = replaceVariations(resultArray, productVariations)
  return finalResult
}
