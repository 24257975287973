const navigationItems = [
  {
    id: 1,
    title: 'كل المنتجات',
    key: 'products',
    to: '/products',
    icon: { icon: 'mdi mdi-store' }
  },
  {
    id: 2,
    title: 'التصنيفات',
    key: 'categories',
    to: '/categories',
    icon: { icon: 'mdi-sitemap-outline' }
  },
  {
    id: 3,
    title: 'الوحدات',
    key: 'attributes',
    to: '/attributes',
    icon: { icon: 'mdi-counter' }
  },
  // {
  //   id: 4,
  //   title: 'معرض الصور',
  //   key: 'images',
  //   to: '/galary',
  //   icon: { icon: 'mdi-image-multiple-outline' }
  // },
  {
    id: 5,
    title: 'العملاء',
    key: 'customers',
    to: '/customers',
    icon: { icon: 'mdi-account-group' }
  },
  {
    id: 6,
    title: 'الطلبات',
    key: 'orders',
    to: '/orders',
    icon: { icon: 'mdi mdi-cart-variant' }
  },
  {
    id: 7,
    title: 'الكوبونات',
    key: 'coupons',
    to: '/coupons',
    icon: { icon: 'mdi-ticket-percent-outline' }
  },
  {
    id: 8,
    title: 'التقارير',
    key: 'reports',
    to: '/reports',
    icon: { icon: 'mdi-file-chart-outline' }
  },
  {
    id: 9,
    title: 'وسائل التوصيل',
    key: '',
    to: '/shipping/zones/0',
    icon: { icon: 'mdi mdi-truck' }
  },
  {
    id: 10,
    title: 'المهملات',
    key: 'trah',
    to: '/trash',
    icon: { icon: 'mdi mdi-delete-variant' }
  },
  {
    id: 11,
    title: 'الاعدادات',
    key: 'settings',
    to: '/settings',
    icon: { icon: 'mdi mdi-cog' }
  }
]

export default navigationItems
