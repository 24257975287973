import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'

import { toast } from 'vue3-toastify'

export async function batchProductsUpdate(payload: any): Promise<ApiResponse> {
  try {
    console.log(payload)
    const { data } = await api.post(`/wp-json/wc/v3/products/batch`, payload)
    toast.success(`  تم تعديل الترتيب بنجاح`)
    return {
      success: true,
      data
    }
  } catch (error) {
    toast.error('حدث خطأ في العملية')
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
