import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { getCategories } from '@/api/categories/getCategories'
import { deleteCategory } from '@/api/categories/deleteCategory'
import { getNames } from '@/helpers/utils'
import type { Category } from '@/types/category'
import type { QueryParameters } from '@/types'

interface CategoryStore {
  categories: Category[]
  totalItems: Ref<number>
  loading: boolean
}

export const useCategoriesStore = defineStore('categories', {
  state: (): CategoryStore => ({
    categories: [],
    totalItems: ref(0),
    loading: false
  }),
  getters: {
    categoryNames(): string[] {
      return getNames(this.categories)
    }
  },
  actions: {
    async getCategories(options: QueryParameters) {
      this.loading = true

      const { data, success } = await getCategories(options)
      if (success) {
        this.categories = data.categories
        this.totalItems = data.total_items
        this.loading = false
      }
    },
    async addCategory(category: Category) {
      this.categories.unshift(category)
      this.incrementTotalItems()
    },
    async deleteCategory(id: number) {
      const { success, error } = await deleteCategory(id)
      if (success) {
        this.categories = this.categories.filter((category: Category) => category.id !== id)
        this.decrementTotalItems()
        return {
          success: true,
          error
        }
      }
      return {
        success: false,
        error
      }
    },

    incrementTotalItems() {
      this.totalItems++
    },

    decrementTotalItems() {
      this.totalItems--
    }
  }
})
